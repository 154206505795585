<template>
  <div class="text-container">
    <p>
      Durch unsere guten Netzwerke im Apothekenmarkt, helfen wir Angebot und
      Nachfrage im Bereich Personal zusammenzuführen. Zudem unterstützen wir Sie
      gerne, sich als Arbeitgeber attraktiv darzustellen und damit Personal zu
      binden.
    </p>
    <ul>
      <li>Übernahme von Approbiertenvertretungen im näheren Umkreis</li>
      <li>Verbindung zu mehreren PTA-Schulen</li>
      <li>Kontaktbörse zur Personalvermittlung</li>
    </ul>
  </div>
</template>

<script>
export default {
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Wir vermitteln Personal für Ihre Apotheke und helfen Ihnen dabei bestehendes Personal zu binden.",
      },
    ],
  },
};
</script>